
<!--
 * @Author: 矢车
 * @Date: 2020-07-25 09:50:41
 * @LastEditors: 矢车
 * @LastEditTime: 2020-09-18 17:43:08
 * @Descripttion: 结算管理 - 导入结算单
-->
<template>
  <div>
    <div class="content">
      <a-button type="primary" :class="step==2 ? 'grays' : ''" style="float: right;" @click="backUp">返回</a-button>
      <div class="uploading" v-if="step===1">
        <a-upload :file-list="fileList" :customRequest="handleFileUpload" accept=".xlsx, .csv">
          <a-button>
            <a-icon type="upload" /> 上传文件 </a-button>
        </a-upload>
        <p class="explain">支持扩展名 .xlsx .csv</p>
        <p style="color: red;">大小不超过5M</p>
      </div>
      <div class="uploaded" v-else-if="step===2">
        <a-spin tip="loading..." size="large"></a-spin>
        <p class="tip">上传成功</p>
        结算单生成中,处理大约需要5分钟...<p class="explain">请勿关闭当前页</p>
      </div>
      <div v-else>
        <div class="mb10 flexLayout">
          <div>
            <a-button class="mr10" @click="againUpload">重新上传</a-button>
            <a-button type="primary mr10" @click="confirmSettle">确认结算单</a-button>
          </div>
          <!-- <a-button type="primary" @click="$router.replace('/admin/shop/settlement')">返回</a-button> -->
        </div>
        <a-table :rowKey="record => record.id" :columns="ToLeadSettlementColumn" :dataSource="list" :pagination="pagination" :loading="listLoading" :locale="{ filterConfirm: '暂无数据' }" @change="handleTableChange">
          <template slot="valid_order_num">
            <div class="tooltips">
              <a-tooltip placement="top">
                <template slot="title">
                  <span>有效结算订单数：本次结算有效订单数。（结算过的订单已去重）</span>
                </template>
                <span>有效结算订单数
                  <a-icon theme="filled" type="question-circle" /> </span>
              </a-tooltip>
            </div>
          </template>
          <template slot="invalid_order_num">
            <div class="tooltips">
              <a-tooltip placement="top">
                <template slot="title">
                  <span>重复结算订单数：子订单已被结算过。（重复导入的订单系统会标记并过滤不会二次结算）</span>
                </template>
                <span>重复结算订单数
                  <a-icon theme="filled" type="question-circle" /> </span>
              </a-tooltip>
            </div>
          </template>
          <template slot="operation" slot-scope="record">
            <a-button type="primary" size="small" @click="clickRow(record)"> 查看明细 </a-button>
          </template>
        </a-table>
      </div>
    </div>
    <template>
      <a-drawer :destroyOnClose="true" title="查看明细" :width="1200" :visible="drawerStatementDetail.isShow" :body-style="{ paddingBottom: '80px' }" @close="onDetailClose">
        <statementDetail :props_rows="rowData"></statementDetail>
      </a-drawer>
    </template>
    <a-modal v-model="visible" title="提示" :closable="false" :maskClosable="false">
      <p>该结算单中包含订单号 【{{xd_order_id}}】已被再次导入待确认中，请核对后重新导入</p>
      <template slot="footer">
        <a-button type="primary" @click="replainUpload"> 重新上传 </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { getOssToken } from "@/service/user";
import { generateUUID } from "@/utils/index";
import {
  postPreSettlement,
  postUploaded,
  putPreSettlement,
  getPreSettlement,
  getSettleFile,
} from "@/service/shop";
import { ToLeadSettlementColumn } from "@/const/shop";
import { statementDetail } from "@/components/admin/shop";
import axios from "axios";

export default {
  components: {
    statementDetail,
  },
  data() {
    return {
      visible: false,
      step: 1, //  当前步骤
      fileList: [],
      xlsxPath: "", //  oss 返回的.xlsx 文件地址
      oss: null,
      ToLeadSettlementColumn,
      list: [],
      timer: null,
      pre_settlement_file_id: "", //  结算单文件id
      xd_order_id: "", //  重复导入的订单号
      drawerStatementDetail: {
        isShow: false,
      },
      rowData: {}, //  当前点击的表格某一行数据
      pagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ["10", "20", "30", "40", "50"],
        showTotal: (total) =>
          `共 ${total} 条记录 第 ${this.pagination.current}/${Math.ceil(
            total / this.pagination.pageSize
          )} 页`,
        showQuickJumper: true,
        showSizeChanger: true,
        onShowSizeChange: (current, pageSize) =>
          (this.pagination.pageSize = pageSize),
      },
    };
  },
  mounted() {
    // this.list = [
    //   {
    //     id: 167,
    //     pre_settlement_file_id: 137,
    //     merchant_id: 4,
    //     valid_order_num: 1,
    //     invalid_order_num: 0,
    //     settlement_amount: 102,
    //     settlement_status: 0,
    //     settlement_amountFormat: 132421,
    //     merchant_name: "\u9ec4\u4f2f\u4f2f",
    //   },
    // ];
    window.addEventListener("beforeunload", (e) => this.beforeunloadHandler(e));

    //  如果刷新了页面,并且刷新前处于 step=3 的情况下
    if (
      localStorage.getItem("isRefresh") &&
      JSON.parse(localStorage.getItem("isRefresh")).isRefresh == "ok" &&
      JSON.parse(localStorage.getItem("isRefresh")).step == 3
    ) {
      this.step = 3;
      this.refreshInterface();
    }
  },
  destroyed() {
    localStorage.removeItem("isRefresh");
  },
  methods: {
    /**
     * @Author: 矢车
     * @Date: 2020-08-11 18:39:48
     * @Description: modal中的重新上传
     */
    replainUpload() {
      this.visible = false;
      this.againUpload();
    },

    /**
     * @Author: 矢车
     * @Date: 2020-08-05 14:34:35
     * @Description: 监听浏览器刷新操作
     */
    beforeunloadHandler() {
      let id =
        this.pre_settlement_file_id ||
        JSON.parse(localStorage.getItem("isRefresh").pre_settlement_file_id);
      localStorage.setItem(
        "isRefresh",
        JSON.stringify({
          step: this.step, // 当前步骤
          isRefresh: "ok", // 当前是刷新的标记
          pre_settlement_file_id: id,
        })
      );
    },

    /**
     * @Author: 矢车
     * @Date: 2020-08-17 16:20:24
     * @Description: 返回上一页
     */
    backUp() {
      if (this.step == 2) {
        return;
      }
      this.$router.replace("/admin/shop/settlement");
    },

    /**
     * @Author: 矢车
     * @Date: 2020-08-05 14:38:38
     * @Description: 调用刷新上传的结算单接口
     */
    async refreshInterface() {
      const { res } = await getSettleFile(
        JSON.parse(localStorage.getItem("isRefresh")).pre_settlement_file_id
      );
      if (res.error_code == 0) {
        if (res.data.is_deleted == 1) {
          this.visible = true;
          this.xd_order_id = res.data.xd_order_id;
        } else {
          const { res } = await getPreSettlement({
            pre_settlement_file_id: JSON.parse(
              localStorage.getItem("isRefresh")
            ).pre_settlement_file_id,
            admin_user_id: JSON.parse(localStorage.getItem("vuex")).user
              .userinfo.id,
            page: this.pagination.current,
            page_size: this.pagination.pageSize,
          });
          if (res.error_code == 0) {
            this.list = res.data.rows.map((item) => {
              return {
                ...item,
                settlement_amountFormat: item.settlement_amount / 100,
              };
            });
            const pagination = { ...this.pagination };
            pagination.total = res.data.total;
            this.pagination = pagination;
            this.step = 3;
          }
        }
      }
    },

    /**
     * @Author: 矢车
     * @Date: 2020-07-31 14:14:30
     * @Description: 点击表格某一行 查看明细
     */
    clickRow(record) {
      this.rowData = record;
      this.drawerStatementDetail.isShow = true;
    },

    /**
     * @Author: 矢车
     * @Date: 2020-07-31 14:36:24
     * @Description: 关闭 drawer
     */
    onDetailClose() {
      this.drawerStatementDetail.isShow = false;
    },

    /**
     * @Author: 矢车
     * @Date: 2020-08-05 10:34:09
     * @Description: 重新上传
     */
    againUpload() {
      this.fileList = [];
      this.xlsxPath = "";
      this.step = 1;
      localStorage.removeItem("isRefresh");
    },

    /**
     * @Author: 矢车
     * @Date: 2020-08-05 10:37:35
     * @Description: 确认结算单
     */
    async confirmSettle() {
      let temp_id = this.pre_settlement_file_id;
      if (
        localStorage.getItem("isRefresh") &&
        JSON.parse(localStorage.getItem("isRefresh")).step == 3
      ) {
        temp_id = JSON.parse(localStorage.getItem("isRefresh"))
          .pre_settlement_file_id;
      }
      let that = this;
      this.$confirm({
        title: "确认后结算单会发送给商家，请仔细操作",
        async onOk() {
          const { res } = await getSettleFile(temp_id);
          if (res.error_code == 0) {
            if (res.data.is_deleted == 1) {
              that.xd_order_id = res.data.xd_order_id;
              that.visible = true;
            } else {
              const { res } = await putPreSettlement(
                {
                  pre_settlement_file_id: temp_id,
                  admin_user_id: JSON.parse(localStorage.getItem("vuex")).user
                    .userinfo.id,
                },
                {
                  timeout: 60000,
                }
              );
              if (res.error_code == 0) {
                that.$message.success("确认成功！");
                that.$router.push("/admin/shop/settlement");
              }
            }
          }
        },
      });
    },

    // 获取osstoken
    async getOssToken() {
      const { err, res } = await getOssToken();
      if (!err && res.success) this.oss = res.data;
    },
    // 文件上传方法
    async handleFileUpload(file) {
      if (file.file.size / 1024 / 1024 > 5) {
        this.$message.error("上传的文件过大！");
        return;
      }

      if (
        !this.oss ||
        this.oss.expire < new Date().getTime().toString().substring(0, 10)
      )
        await this.getOssToken();
      if (!this.oss) {
        this.$message.warn("上传失败");
        return false;
      }
      const formData = new FormData();
      const pathArr = file.file.name.split(".");
      const key = `${this.oss.dir}douyin-goods/${generateUUID()}.${
        pathArr[pathArr.length - 1]
      }`;
      formData.append("key", key);
      formData.append("policy", this.oss.policy);
      formData.append("signature", this.oss.signature);
      formData.append("success_action_status", "200");
      formData.append("OSSAccessKeyId", this.oss.accessid);
      formData.append("file", file.file);

      axios({
        method: "post",
        url: this.oss.host,
        data: formData,
      })
        .then(async (response) => {
          const { err } = response;
          if (!err) {
            //  获取到从 oss 返回的 .xlsx 的文件链接
            let xlsxPath = this.oss.host + "/" + key;
            this.fileList.splice(0, 1, file.file);
            this.xlsxPath = xlsxPath;
            this.step = 2;

            const { err, res } = await postPreSettlement({
              file_path: xlsxPath,
              admin_user_id: JSON.parse(localStorage.getItem("vuex")).user
                .userinfo.id,
              admin_user_name: JSON.parse(localStorage.getItem("vuex")).user
                .userinfo.username,
              page: this.pagination.current,
              page_size: this.pagination.pageSize,
            });
            if (res !== null && res.error_code === 0) {
              /*
                1. 拿到返回的 poll_id 请求接口
                2. 轮循此接口,如果data.rows有返回列表数据,视为成功,跳回step3,如果没有,或者报错,视为失败,继续请求
                3. 最多等待 5 分钟,5分钟后还未成功,也为上传失败
                4. 上传失败的话返回到 step=1 那个步骤
                */
              this.postUploadedInterface(res.data.poll_id);

              this.timer = setTimeout(async () => {
                //  如果当前还在第二步,则失败
                if (this.step == 2) {
                  clearTimeout(this.timer);
                  this.$message.error("上传失败,请重新上传");
                  this.step = 1;
                  this.xlsxPath = "";
                  this.fileList = [];
                }
              }, 300000);
            } else {
              this.$message.error(err);
            }
          } else {
            this.$message.error("上传失败，请稍后重试");
          }
        })
        .catch((error) => {
          this.$message.error(`${error}`);
        });
    },

    /**
     * @Author: 矢车
     * @Date: 2020-08-05 10:19:10
     * @Description: 长轮循接口
     */
    async postUploadedInterface(poll_id) {
      const { res } = await postUploaded(poll_id);
      if (res && res.data.rows && res.data.rows.length > 0) {
        //  成功
        this.$message.success("上传成功！");
        this.list = res.data.rows.map((item) => {
          return {
            ...item,
            settlement_amountFormat: item.settlement_amount / 100,
          };
        });
        const pagination = { ...this.pagination };
        pagination.total = res.data.total;
        this.pagination = pagination;
        //  结算单 id 赋值
        this.pre_settlement_file_id = res.data.rows[0].pre_settlement_file_id;
        this.step = 3;
        clearTimeout();
      } else if (res && res.data.message == "waiting") {
        //  失败,继续轮循
        if (this.step != 1) {
          this.postUploadedInterface(poll_id);
        }
      } else {
        //  失败,结束，回到第一步
        this.step = 1;
        this.xlsxPath = "";
        this.fileList = [];
        clearTimeout(this.timer);
      }
    },
    // 跳页
    handleTableChange(pagination) {
      if (pagination) this.pagination.current = pagination.current;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.uploading,
.uploaded {
  padding: 50px 0;
  width: 500px;
  margin: auto;
  text-align: center;
}
.uploading {
  .explain {
    margin-top: 20px;
  }
  p {
    font-size: 13px;
    line-height: 20px;
  }
}
.uploaded {
  .tip {
    margin-top: 20px;
    font-weight: bold;
  }
  .explain {
    font-weight: bold;
    color: red;
    font-size: 15px;
    margin-top: 10px;
  }
}
.flexLayout {
  display: flex;
  justify-content: space-between;
}
.grays {
  background: #c7c7c7;
  border-color: #c7c7c7;
  color: white;
}
</style>

<style scoped>
.content >>> .ant-upload-list-item-name {
  width: auto;
}
.content >>> .ant-spin-text {
  font-size: 20px;
}
.content >>> .ant-upload-list-item-card-actions {
  display: none;
}
.content >>> .ant-tooltip-placement-top {
  display: none;
}
.content >>> .ant-upload-list {
  margin-top: 10px;
}
</style>


